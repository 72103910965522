/*fonts*/
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
@font-face {
	font-family: 'Jameel Noori Nastaleeq';
	src: url('../font/JameelNooriNastaleeq.ttf');
	/* src: url('../font/JameelNooriNastaleeq.ttf') */
			/* format('embedded-opentype'), */
		/* url('../font/JameelNooriNastaleeqKasheeda.woff2') format('woff2'), */
		/* url('../font/JameelNooriNastaleeq.woff') format('woff'), */
		/* url('../font/JameelNooriNastaleeqKasheeda.woff') format('woff'), */
		/* url('../font/JameelNooriNastaleeq.ttf') format('truetype'), */
		/* url('../font/Jameel-Noori-Nastaleeq.svg') */
			/* format('svg'); */
		/* url('../font/JameelNooriNastaleeqKasheeda.svg#JameelNooriNastaleeqKasheeda')
			format('svg'); */
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Noorehuda Regular';
	src: url('../font/noorehudaRegular.ttf');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

 
html,
body {
	height: 100%;
	width: 100%;
	margin: 0;
	font-family: 'Jameel Noori Nastaleeq' !important;
}

body {
	margin: 0;
	font-family: 'Jameel Noori Nastaleeq'!important;
	/* background: linear-gradient(#5c6878f8, #5c6878f8),
    url(../Images/Background/arabic_bg.jpg);
  background-repeat: no-repeat; */
	/* background-color: #5c6878; */
	background-image: url(../Images/Background/banner.png);
	background-repeat: no-repeat;
	background-size: cover;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	background-size: cover;
	background-attachment: fixed;
	/* min-height: 100vh; */
	max-height: 100%;
	overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: grey;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #0a3147;
}
.container {
	width: 100%;
	padding-right: 0px;
	padding-left: 0px;
	margin-right: auto;
	margin-left: auto;
}

.PrivateSwitchBase-input-66 {
	color: blue;
}

.home,
.mashaq-panel {
	display: flex;
	flex-direction: column;
	font-family: 'Jameel Noori Nastaleeq';
	font-weight: normal;
	background-color: transparent;
	/* min-height: 100vh; */
	background-size: cover;
	width: 100%;
	background-position: 50%;
	/* height: 100vh; */
}

.mashaq-panel .MuiButton-text {
	margin: 1rem 0rem 0rem;
	background-color: #084567;
	color: #fff;
}
.mashaq-panel .MuiTabs-flexContainer {
	justify-content: center;
}
.MuiGrid-spacing-xs-3 {
	width: calc(100%) !important;
}

a.active,
a:focus,
button:focus,
button.active {
	outline: none;
}

.text-center {
	text-align: center !important;
}

a {
	text-decoration: none;
}

.btn:focus {
	box-shadow: none;
}

a:focus,
a:hover,
a:active {
	outline: 0;
	box-shadow: 0;
}

a:focus,
a:hover {
	text-decoration: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6 {
	font-weight: normal;
	margin-bottom: 0;
	font-family: 'Jameel Noori Nastaleeq';
}

a {
	transition: all ease-in-out 0.5s;
	text-decoration: none;
}
/* global classes */
.urdu {
	font-family: "Jameel Noori Nastaleeq" !important;
}
/* ends global classes */
button {
	transition: all ease-in-out 0.5s;
}

a:hover {
	text-decoration: none;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

ul li {
	list-style: none;
}

p {
	margin: 0;
}

input:focus,
select:focus,
textarea:focus {
	outline: none;
}

figure {
	margin: 0;
}

img {
	/* border-style: none;
  display: inline-block;
  max-width: 100%;
  height: auto; */
	width: 327px;
}

.form-control:focus {
	box-shadow: none;
}

.vertical {
	width: '100%';
}

.wrapper {
	position: relative;
	overflow: hidden;
}
.MuiFormControlLabel-label,
.MuiTab-wrapper 
{
	font-family: 'Jameel Noori Nastaleeq' !important;
}
.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}

.flex-column {
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}

.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
}

.logo {
	width: 250px;
	height: 72px;
}
.arabic {
	font-family: 'Noorehuda Regular';
}

header {
	padding: 17px 0 0 0;
}

header .nav-link {
	font-size: 30px;
}

header .nav-item {
	margin-right: 40px;
}

header .nav-item:last-child {
	margin-right: 0;
}

header .nav-link:after {
	display: none;
	content: '';
	background-color: #fff;
	width: 100%;
	height: 2px;
	position: absolute;
	bottom: -6px;
	left: 0;
}

header .nav-item.active .nav-link:after {
	display: block;
}

.dashbord-sec {
	display: 'flex';
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	min-width: 320px;
}

.kalambox {
	margin-bottom: 39px;
}

.test-box {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.08);
	border-radius: 18px;
	margin: 12px 20px;
	background: #fff !important;

	/* width: 30vw;
  height: 25vh; */
	height: 30vh;
}

.test-box img {
	margin-bottom: 20px;
	width: 102px;
	height: 102px;
}

.test-box span {
	color: #000;
	font-size: 24px;
}

.navigation {
	margin-bottom: 80px;
}

.card {
	/* padding: 0px 15px 1px; */
	text-align: center;
	margin: 0 auto;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: 100%; */
}
.card .MuiCollapse-wrapper {
	max-height: 50vh;
	overflow-y: auto;
}
.card .MuiCollapse-wrapper 

/* width */
.card .MuiCollapse-wrapper::-webkit-scrollbar {
	width: 20px;
}

/* Track */
.card .MuiCollapse-wrapper::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgb(226, 226, 226);
	border-radius: 10px;
}

/* Handle */
.card .MuiCollapse-wrapper::-webkit-scrollbar-thumb {
	background: grey;
	border-radius: 10px;
}

/* Handle on hover */
.card .MuiCollapse-wrapper::-webkit-scrollbar-thumb:hover {
	background: #0a3147;
}

.mashaq-panel .card {
	padding-top: 15px;
	/* height: 100vh; */
}
.card h2 {
	font-size: 56px;
	text-align: center;
	align-items: center;
	color: white;
	font-family: 'Noorehuda Regular';
	
}

.card h2 img {
	margin: -10px 7px;
}

.card h3 {
	color: #fff;
}

.report-card-main-class .result-page-content {
	flex-direction: column;
	min-height: 110vh;
}

/*responsive*/

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
		margin: 0 auto !important;
		padding: 0;
	}
}

/* .home button {
  padding: 10px 20px;
} */

/*----------------------------------------
Login Page
------------------------------------------*/

.login-page {
	background: 'transparent';
	/* height: 100vh; */
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login-card {
	width: 600px;
	/* display: flex; */
	justify-content: center;
	background: white;
	border-radius: 24px;
	padding: 26px;
	text-align: center;
}

/* .login-card h6 {
  font-family: 'Noto Sans Arabic UI';
  font-weight: 300;
  font-size: 33px;
  line-height: 133%;
  text-align: center;
  color: #454545;
  margin-bottom: 28px;
} */

.login-card h6:last-of-type {
	margin-bottom: 1rem;
}

.login-card .MuiFormControl-root {
	margin-bottom: 10px;
	width: 80% !important;
}
.login-card .MuiFormControl-root input {
	padding: 13.5px 14px;
}
.login-card .MuiOutlinedInput-root {
	border-radius: 13px;
}
.login-card .MuiInputLabel-outlined {
	transform: translate(15px, 15px) scale(1);
}

.login-card button {
	background: #084567;
	border-radius: 80px;
	color: #fff;
	margin: 15px auto;
	width: 80%;
	font-size: larger;
}

.login-card span.MuiButton-label {
	padding: 10px;
}

.login-card p.signbottom {
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 81%;
	text-align: center;
	color: #000000;
}
.forgetPassword-div {
	display: flex;
	margin: 1px auto;
	width: 80%;
	text-align: left;
}

/*----------------------------------------
 HAdiyat Page
------------------------------------------*/

.hadiyat form {
	font-family: 'Jameel Noori Nastaleeq';
	display: flex;
	justify-content: center;
	flex-direction: row-reverse;
	align-content: center;
	margin-top: 30px;
	margin-bottom: 30px;
}

.hadiyat input {
	font-family: 'Jameel Noori Nastaleeq';
	padding: 10px 20px;
	background: #ffffff;
	box-shadow: 0px 12px 24px rgb(0 0 0 / 10%);
	border-radius: 79px;
	border: 1px solid #eee;
	text-align: right;
	width: 30%;
	font-size: 16px;
}

.hadiyat button {
	font-family: 'Jameel Noori Nastaleeq';
	background: #084567;
	border-radius: 80px;
	color: white;
	padding: 0 20px;
	margin-right: 10px;
	border: 0;
}

.hadiyat h5 {
	background: #ffffff;
	box-shadow: 0px 4px 14px rgb(0 0 0 / 16%);
	border-radius: 16px;
	font-size: 30px;
	padding: 7px;
}

/*----------------------------------------
 Topic Page
------------------------------------------*/

.no-record {
	display: flex;
	justify-content: center;
	align-items: 'center';
}

.result-page-content {
	/* background-color: blue; */
	background-image: url(../Images/Background/starVector.svg);
	background-repeat: no-repeat;
	background-size: 50%;
	/* background-attachment: fixed; */
	background-position: center;
	background-color: #ffff;
	height: 85vh !important;

	display: flex;
	/* flex: 1; */
	justify-content: start;
	align-items: flex-start;
	/* margin: 0px 10px; */
	padding: 0%;
}

.topicpage1 {
	background-image: url(../Images/Background/starVector.svg);
	background-repeat: no-repeat;
	background-size: 50%;
	background-position: center;
	background-color: #ffff;
	max-height: 85vh;
	display: flex;
	/* flex: 1; */
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 20;
	overflow-x: hidden;
}

.topicpage {
	/* background-color: blue; */
	background-image: url(../Images/Background/starVector.svg);
	background-repeat: no-repeat;
	background-size: 50%;
	background-position: center;
	background-color: #ffff;
	min-height: 85vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.topicpage .MuiButtonBase-root.MuiAccordionSummary-root {
	flex-direction: row-reverse;
	justify-content: space-between;
}

.topicpage
	.MuiButtonBase-root.MuiAccordionSummary-root
	.MuiAccordionSummary-content {
	flex-grow: unset;
}
.MuiSvgIcon-root {
	color: black;
}
.topicpage .MuiAccordion-root.Mui-expanded {
	margin: 0;
}

/* .topicpage .MuiAccordionDetails {
  background-color: cadetblue;
} */

.topicpage
	.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
	box-shadow: 0px 12px 24px rgb(0 0 0 / 10%);
	background-color: rgb(12, 70, 107);
	border-radius: 12px;
	margin-bottom: 23px;
	color: #fff;
	width: 100%;
}

.topicpage .MuiTypography-body1 {
	font-family: 'Noorehuda Regular';
	font-size: 30px;
}
.topicpage-grid {
	margin-top: 1rem;
}
.topicpage-grid .MuiButton-root {
	text-align: center;
	font-size: 18;
	color: whitesmoke;
	/* // padding: 5px 20px; */
	border-radius: 25px;
	outline: none;
	background-color: #074567;
	width: 15vw;
	margin: 0;
}
.topicpage-grid .MuiButton-root.Mui-disabled {
	background-color: rgba(104, 124, 134, 0.9);
	color: whitesmoke;
}
.topicpage-grid .MuiButton-root:hover {
	background-color: transparent;
	color: #074567;
	box-shadow: 0px 0px 11px 4px rgba(7, 69, 103, 0.75);
	-webkit-box-shadow: 0px 0px 11px 4px rgba(7, 69, 103, 0.75);
	-moz-box-shadow: 0px 0px 11px 4px rgba(7, 69, 103, 0.75);
}
.topicpage-grid .MuiGrid-item input {
	background-color: whitesmoke;
	text-align: right;
	font-size: 18;
	color: black;
	padding: 5px 10px;
	border-radius: 25px;
	outline: none;
	width: 30vw;
	margin-left: 1rem;
}
.topicpage-inn {
	width: 80%;
	padding: 20px;
}

.selection-button-mashaq-fail {
	background-color: #fff !important;
	border-radius: 10px !important;
	padding: 0.8rem !important;
	font-size: 1rem !important;
	box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.5) !important;
	-webkit-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.5) !important;
	-moz-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.5) !important;
}
.selection-input-mashaq-fail {
	border: none;
	background-color: white;
	background-image: url('../Images/Icon/search.svg');
	background-repeat: no-repeat;
	background-size: 30px;
	background-position: 98%;
	text-indent: 20px;
	text-align: right;
	font-size: 18;
	color: black;
	padding: 5px 45px;
	outline: none;
	width: 100%;
	height: 40px;
	border-radius: 25px;
	box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.5) !important;
	-webkit-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.5) !important;
	-moz-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.5) !important;
}

/* Sweet AlertStyle */

.sweet-alert a.btn.btn-lg {
	background: #084567;
	border-radius: 80px;
	margin-right: 8px;
	border-color: rgb(40, 96, 144);
	box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px;
	padding: 5px 42px;
	color: #fff;
}
/* 
.topicpage .MuiTypography-body1 {
	font-family: 'Jameel Noori Nastaleeq';
	font-size: 20px;
	background-color: rgb(0 0 0 / 0%);
} */

.topicpage .MuiAccordion-root:before {
	background-color: transparent;
}

.topicpage .MuiAccordionDetails-root,
.topicpage .MuiAccordionDetails-root p {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
}

.topiccard {
	/* background: brown; */
	/* box-shadow: 0px 4px 14px rgb(0 0 0 / 16%); */
	/* border-radius: 16px; */
	/* background-color: #838792; */
	background-color: white;
	color: black;
	/* border: 1px solid black; */
	/* border-radius: 16px; */
	border-width: 5 !important;
	border-color: burlywood !important;
	border-color: white;

	font-weight: 700;
	padding: 22px 0px;
	margin-right: 2px;
	margin-bottom: 3px;
}

.topiccard a {
	color: black;
}

@media screen and (max-width: 768px) {
	.topicpage {
		/* background-color: blue; */
		background-image: url(../Images/Background/starVector.svg);
		background-repeat: no-repeat;
		background-size: 50%;
		background-position: center;
		background-color: #ffff;
		min-height: 88vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.quiz .MuiFormControlLabel-root {
		margin-left: 0;
		margin-right: 16px;
		vertical-align: middle;
		-webkit-tap-highlight-color: transparent;
		justify-content: space-between;
		align-content: center;
		width: 100%;
		/* background-color: rebeccapurple; */
		/* padding: 10px 20px; */
		border-bottom: 0.5px solid grey;
	}
	.login-card {
		width: 97vw;
	}
	.login-card .MuiFormControl-root {
		margin-bottom: 10px;
		width: 95% !important;
	}
	.login-card button {
		background: #084567;
		border-radius: 80px;
		color: #fff;
		margin: 15px auto;
		width: 95%;
		font-size: larger;
	}
	.result-page-content {
		height: 90vh !important;
		padding: 2% 0%;
	}
	.report-card-main-class .result-page-content {
		flex-direction: column;
		min-height: 90vh;
	}
	.forgetPassword-div {
		display: flex;
		margin: 1px auto;
		width: 95%;
		text-align: left;
	}

	.topicpage {
		height: auto !important;
		background-color: #fff;
	}
	.topicpage-grid {
		flex-direction: column-reverse;
	}

	.topicpage-grid .MuiButton-root {
		margin-top: 1rem;
		width: 85vw;
	}
	.topicpage-grid .MuiGrid-item input {
		width: 90vw;
		margin-left: 0;
		padding: 10px 15px;
	}
	.topicpage-inn {
		width: 100vw;
	}
	.question-card-main {
		width: 100vw !important;
	}
	fieldset.MuiFormControl-root.quiz {
		width: 95vw !important;
	}
	.tabpanel {
		color: black;
		height: 100vh;
		margin-bottom: 0;
	}
	.logoDiv {
		display: flex;
		justify-content: center;
		align-items: center;
		/* height: 57vh; */
	}
	/* .home button {
    padding: 0px;
  } */

	.test-box {
		width: 32.7vw;
		height: 17vh;
		margin: 1px 1px;
	}
	.test-box img {
		width: 40%;
		height: 40%;
	}
	.test-box span {
		font-size: 1rem;
	}
	.topic_detail_tabs-1 {
		display: flex;
		flex-direction: column;
		color: whitesmoke;
		width: 90vw;
	}
	.MuiTab-root {
		padding: 0px 0px;
	}
	.topic_detail_tabs-1 .MuiTab-root {
		font-size: 1.25rem;
		color: #104a6c;
	}
	.makeStyles-root-49 .MuiTab-root {
		background-color: #937854;
	}
	.mashaq-panel .MuiTabs-flexContainer button:first-of-type {
		margin-left: 22rem;
	}
	.topic_detail_tabs-1 .MuiTabs-indicator {
		background-color: #104a6c !important;
	}
	.mashaq-panel .card {
		padding-top: 0px;
		/* background-color: #fff !important; */
		padding-top: 1rem;
	}
	.card {
		/* padding: 15px 15px; */
		background-color: 'whitesmoke' !important ;
		/* min-height: 90.3vh; */
	}
	.topic_detail_tabs-1 .tabsdetail_right {
		background: transparent;
		border-radius: 0px 12px 12px 0px;
		height: max-content;
	}
	.topic_detail_tabs-1 .tabsdetail_right span,
	.topic_detail_tabs-1 .tabsdetail_left span {
		font-size: large;
		padding: 0;
		color: #104a6c;
		font-size: 1.5rem;
	}
	.noun-gardan-div span {
		margin: 1.7rem 0rem;
	}
	.topic_detail_tabs-1 .MuiBox-root {
		padding: 11px 8px;
	}
	.sweet-alert > p a {
		margin-bottom: 0.9rem;
	}
}

/* Question Style */
.question-card-main {
	border-radius: 10px;
	box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.5) !important;
	-webkit-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.5) !important;
	-moz-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.5) !important;
	width: min-content;
	margin: 1rem auto;
}
.question-card-main .Mui-checked .MuiSvgIcon-root {
	color: #f8dc0f !important;
}
fieldset.MuiFormControl-root.quiz {
	width: 50vw;
	padding: 5px auto;
	/* margin: 0.5rem auto; */
	/* border: 1px solid #e3e3e3; */
	border-bottom: 1px solid #e3e3e3;
}
fieldset.MuiFormControl-root:nth-of-type(even) div {
	background-color: rgb(145, 145, 145);
}

fieldset.MuiFormControl-root:nth-of-type(odd) div {
	background-color: transparent;
}
.question-grid div:last-of-type label {
	border-bottom: none;
}
/* .MuiIconButton-label .PrivateSwitchBase-input {
  color: yellow;
} */
.quiz .MuiFormControlLabel-root {
	margin-left: 0;
	/* margin-right: 16px; */
	vertical-align: middle;
	-webkit-tap-highlight-color: transparent;
	justify-content: space-between;
	align-content: center;
	width: 100%;
	/* background-color: rebeccapurple; */
	/* padding: 10px 20px; */
	border-bottom: 0.5px solid grey;
}
.quiz-div {
	margin-top: 10px;
}
.quiz-div .MuiGrid-item {
	padding: 0rem 1rem;
}

.quiz .MuiTypography-root.MuiFormControlLabel-label {
	color: #0f456b;
}
/* .quiz .MuiBox-root {
  padding: 0%;
} */
/* .quiz span.MuiRadio-root.Mui-checked {
  color: greenyellow !important;
} */
.result-page {
	display: 'flex';
	justify-content: center;
	align-items: center;
	/* background-color: black; */

	width: '100%';
	height: '100%';
}
.questionCardHeadings {
	/* display:flex;
  flex: 1; */
	/* height: 80vh; */
	width: 100%;
	/* background-color: whitesmoke; */
}

.questionCardHeadings h2 {
	color: #f8dc0f;
}
.questionCardHeadings h2 span {
	color: #fff;
	margin-right: 0.9rem;
}

/* Quiz Result page */

.result .MuiListItemText-multiline {
	text-align: right;
}
.result .MuiListItemAvatar-root {
	background: #434b58;
	border-radius: 0 12px 12px 0;
}
.result .MuiListItemAvatar-root h2 {
	color: #fff;
}
.result span.MuiTypography-body1.MuiTypography-displayBlock {
	font-family: 'Jameel Noori Nastaleeq';
	font-size: 29px;
}
.result .MuiListItem-gutters {
	background: #ffffff;
	box-shadow: 0px 12px 24px rgb(0 0 0 / 10%);
	border-radius: 15px;
	padding: 0;
	margin: 0.1rem 0rem 0.5rem;
}
.result .MuiListItemText-multiline {
	margin-right: 15px;
}

/* Topic DEtail Tab page */
.topic_detail_tabs {
	display: flex;
	flex-grow: 1;
	background-color: #b5c7d1;
	flex-direction: row-reverse;
	border-radius: 12px;
	width: 60vw;
	margin-top: 1rem;
}
.topic_detail_tabs_1 .MuiTabs-indicator {
	background-color: yellow;
}
.topic_detail_tabs-tabs .MuiTabs-flexContainer {
	justify-content: center !important;
}
.topic_detail_tabs-tabs .MuiTabs-scroller > span {
	background-color: whitesmoke !important;
}
.topic_detail_tabs .MuiTab-wrapper {
	font-family: 'Jameel Noori Nastaleeq';
	font-size: 24px;
}
.topic_detail_tabs .MuiTabs-root {
	min-width: 23%;
	background: #084567;
	border-radius: 0px 12px 12px 0px;
	color: #fff;
	height: max-content;
}
/* .topicpage .MuiTabs-root {
} */
.topic_detail_tabs .MuiTab-textColorInherit {
	border: 1px solid #0a3147;
}

.topic_detail_tabs .MuiTab-textColorInherit.Mui-selected {
	background: #185b81;
	color: white;
}
.topic_detail_tabs .tabs-left {
	margin: 1rem 0rem;
	width: 100%;
}
.tabs-left .MuiBox-root-5 {
	padding: 0px 24px;
}

.tabsdetail .MuiGrid-grid-xs-6 {
	text-align: right;
}

.tabsdetail_right {
	/* background: #b5c7d1; */
	border-radius: 0px 12px 12px 0px;
	/* height: max-content; */
}
/* .tabsdetail_right h5:last-child {
  border-bottom: 0;
} */

.tabsdetail_left span,
.tabsdetail_right span {
	/* border-bottom: 1px solid #e9e9e9; */
	border-bottom: 1px solid #527d95;
	background-color: transparent;
	display: flex;
	justify-content: space-between;
	padding: 0px 12px;
}
/* 
.tabsdetail_left h5:last-child {
  border-bottom: 0;
} */

.MuiGrid-spacing-xs-3 > .MuiGrid-item p {
	float: left;
	font-family: 'Noorehuda Regular';
}
.MuiGrid-spacing-xs-3 > .MuiGrid-item {
	padding: 0;
}

.PrivateTabIndicator-colorSecondary {
	background-color: #f5005700 !important;
}

.section_card {
	margin: 0 auto !important;
	box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
}
.mt-2 {
	margin-top: 20px;
}
.section_card .tabsdetail .MuiGrid-grid-xs-6 {
	padding: 12px;
}

.section_card .report h5 {
	font-size: 30px;
}

.report_ans .MuiAccordionSummary-content {
	flex-direction: row-reverse;
	margin: 0;
	padding: 10px;
}
.report_ans .MuiAccordionSummary-content h5 {
	color: #fff;
	display: flex;
}
.report_ans .MuiAccordionSummary-root {
	flex-direction: row-reverse;
	background: #0f456b;
	border-radius: 12px;
	min-height: 36px;
}
.quiz_status {
	background: #dd5144;
	border-radius: 12px 0px 0px 0px;
	position: absolute;
	left: 0px;
	padding: 10px;
	top: 0;
}
.report_ans .MuiIconButton-edgeEnd {
	padding: 0;
}
.report_ans .MuiIconButton-edgeEnd {
	margin-left: 92px;
	margin-right: 0;
}
.MuiAccordionSummary-root.Mui-expanded,
.report_ans .MuiAccordionSummary-root {
	height: 65px;
}
.main-table {
	width: 100%;
	margin: 0 auto;
}

.main-sheet {
	width: 100%;
	display: flex;
}

.main-header1 {
	width: 10%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.main-header2 {
	width: 35%;
	align-items: center;
	display: flex;
	justify-content: center;
}

.main-header3 {
	width: 35%;
	align-items: center;
	display: flex;
	justify-content: center;
}

.main-header4 {
	width: 20%;
	align-items: center;
	display: flex;
	justify-content: center;
}

.main-header1 img {
	width: 50%;
	margin: 0 auto;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
}

.border {
	border: 1px solid #f0f0f0;
}

/* .makeStyles-root-6 .MuiTab-root {
  color: #fff;
  padding: 12px 50px;
  font-size: 1.2rem;
} */
@media screen and (min-width: 768px) and (max-width: 959px) {
	.topic_detail_tabs-1 .MuiTab-root {
		color: white;
	}
	.topic_detail_tabs-1 {
		color: whitesmoke;
	}
	.topic_detail_tabs {
		width: 80vw;
	}
	.questionCardHeadings {
		height: 80vh;
	}
}
.text-field input,.list-item span {
	font-family: "Jameel Noori Nastaleeq";
	direction: rtl !important;
	color: white;
	font-weight: 700;
}
.text-field svg{
	color: white;
	font-weight: 200;
}
.list-item span, .list-item p {
	text-align: right;
	color: white;
	font-weight: 700;
}
.arabic{
	font-family: 'Noorehuda Regular';
	direction: rtl;
}
h5 img{
	height: 15px;
	width: 15px;

}
.result-area{
	padding: 20px;
	background-color: red;
}
.result-area h4, .result-area h5{
	padding: 10px 0px;
}



