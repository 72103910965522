/* Tool Bar CSS */

.toolbar_navigator {
  display: flex;
  align-items: center;
  height: 100%;
  /* padding: 0 1rem; */
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

/* .toolbar_navigation-items li {
  padding: 0 0.5rem;
} */

.toolbar_navigation-items a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  margin: 0 10px;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: burlywood;
}

.dashboard_title:hover{
  color: burlywood !important;
  transition: all ease-in-out 0.5s;
}

.dashboard_title{
  color:black !important ;
  font-size: 1rem;
}
@media (min-width: 758px) and (max-width: 958px) {


  .toolbar_navigation-items {
    display: none;
  }
  .toolbar_navigator {
    flex-direction: column;
  }
  .toggle-btn {
    /* display: block !important; */
    display: flex;
  }
  .toggle-button-line {
    display: flex !important;
    flex-direction: column !important;
    margin: 10;
  }
}

@media screen and (max-width: 760px) {
  .toolbar_navigation-items {
    display: none;
  }
  .toolbar_navigator {
    flex-direction: column;
  }
  .toggle-btn {
    display: block;
  }
}

@media screen and (min-width: 759px) {
  .toggle-btn {
    display: none;
  }
  .toolbar_logo {
    margin-left: 0;
  }
}

/* Site Drawser css */

.side-drawer {
  height: 100%;

  /* background-image: url(../../Assets/Images/Background/banner.png);
  background-repeat: no-repeat; */
  background-color: #5c6878;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  /* right: 0; */
  width: 70%;
  max-width: 400px;
  /* z-index: 200; */
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}
.side-drawer-right {
  height: 100%;

  /* background-image: url(../../Assets/Images/Background/banner.png);
  background-repeat: no-repeat; */
  background-color: #5c6878;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  /* left: 0; */
  right: 0;
  width: 70%;
  max-width: 400px;
  /* z-index: 200; */
  transform: translateX(143%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
  z-index: 200;
}
.side-drawer-right.open {
  transform: translateX(0);
  z-index: 200;
}

.side-drawer ul,
.side-drawer-right ul {
  height: auto;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-drawer li,
.side-drawer-right li {
  margin: 0.5rem 0;
  border-bottom: 0.1em solid white;
}

.side-drawer a,
.side-drawer-right a {
  text-decoration: none;
  color: white;
  font-size: 0.7rem;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: burlywood;
}

.side-drawer-right a:hover,
.side-drawer-right a:active {
  color: burlywood;
}

@media screen and (min-width: 759px) {
  .side-drawer {
    display: block;
  }
  .side-drawer-right {
    display: block;
  }
}

/* inll howa k ni?sta 
kr lain inst
*/

/* Side Menu Styule */

.toggle-button {
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button-line {
  width: 30px;
  height: 2px;
  background: white;
}

/* Backdrop Css */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.3); */
  background: rgba(201, 52, 52, 0.3);
  z-index: 100;
}

.layout {
  font-family: 'Jameel Noori Nastaleeq';
  /* background-image: url(../../Assets/Images/Background/banner2.svg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 142px; */
}
/* .MuiBox-root .MuiBox-root-3{
  display: flex;
  flex-direction: row;
  background-color: white;
} */

.uniqueListStyle {
  font-size: 25px;
  color: #fff;
  cursor: pointer;
}
.uniqueListStyle:hover,
.uniqueListStyle:active {
  color: burlywood;
}

.lngbtn :hover{
  background-Color: 'white !important';
  
}


